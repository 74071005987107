import { useState, useEffect, useContext, createContext } from 'react'
import { useRouter } from 'next/router'
import { initializeApp, getApps } from 'firebase/app'
import cleanObject from './cleanObject'
import { firebaseErrors } from './firebaseErrors'
import { message, notification } from 'antd'
import { PeopleFirst } from '../components/loader'
import { AnalyticsBrowser } from '@segment/analytics-next'
import axios from 'axios'
import { toast } from 'react-toastify'

import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut as fSignOut,
  sendPasswordResetEmail as fSendPasswordResetEmail,
  confirmPasswordReset as fConfirmPasswordReset,
  verifyPasswordResetCode,
  reauthenticateWithCredential,
  updatePassword,
  EmailAuthProvider,
  sendEmailVerification,
  GoogleAuthProvider,
  updateEmail,
  linkWithPopup,
  signInWithPopup,
  signInWithCredential,
  signInWithCustomToken,
} from 'firebase/auth'

import {
  getFirestore,
  setDoc,
  Timestamp,
  doc,
  getDoc,
} from 'firebase/firestore'

import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'

const config = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: 'auth.creators.peoplefirst.cc',
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
}
const provider = new GoogleAuthProvider()

// initialize auth context
const AuthContext = createContext()

// hook that we can use anywhere in the app
export const useAuth = () => useContext(AuthContext)

// this will be the wrapper of the whole auth context that will be used in _app.js
export const AuthProvider = ({ children }) => {
  const auth = useAuthProvider()
  return (
    <AuthContext.Provider value={auth}>
      {auth.loading ? <PeopleFirst /> : children}
    </AuthContext.Provider>
  )
}

// firebase functions are defined here
const useAuthProvider = () => {
  const router = useRouter()
  const analytics = AnalyticsBrowser.load({
    writeKey: process.env.NEXT_PUBLIC_SEGMENTIO_KEY,
  })
  let firebaseApp
  if (!getApps().length) {
    firebaseApp = initializeApp(config)
  }
  const auth = getAuth(firebaseApp)
  const db = getFirestore(firebaseApp)

  const [currentUser, setcurrentUser] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log('Fetching User Profile....')
        const docRef = doc(db, 'users', user.uid)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
          const profile = docSnap.data()

          if (!profile.paymentTier) {
            console.log('Payment Tire:', profile)
            profile.paymentTier = '1'
          }
          setcurrentUser({ ...profile, ...user })
          analytics.identify(user.uid, {
            ...profile,
            email: user.email,
            emailVerified: user.emailVerified,
          })
        }
      } else {
        setcurrentUser(null)
      }
      setLoading(false)
    })

    return unsubscribe
  }, [])

  useEffect(() => {
    router.events.on('routeChangeComplete', async () => await analytics.page())
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init('437759087804394')
        ReactPixel.pageView()

        router.events.on('routeChangeComplete', () => {
          ReactPixel.pageView()
        })
      })
  }, [router.events])

  const signIn = async (email, password) => {
    await signInWithEmailAndPassword(auth, email, password)
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.track('Login', {
          status: 'success',
          type: 'email',
        })
      })
  }
  const signInWithToken = async (token) => {
    const credential = GoogleAuthProvider.credential(token)
    console.log(credential)
    await signInWithCustomToken(auth, token)
  }

  const signInWithGoogle = async () => {
    await signInWithPopup(auth, provider)
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.track('Login', {
          status: 'success',
          type: 'google',
        })
      })
  }

  const signUp = async (email, password, firstName, lastName) => {
    const response = await createUserWithEmailAndPassword(auth, email, password)

    await setDoc(doc(db, 'users', response.user.uid), {
      firstName,
      lastName,
      email,
      emailVerified: false,
      roles: ['user'],
      timestamp: Timestamp.now(),
    }).catch((error) => {
      console.log(error)
    })
    await sendEmailVerification(auth.currentUser)

    return response
  }

  const signOut = async (status) => {
    await fSignOut(auth)
    router.replace('/')
  }

  const sendPasswordResetEmail = async (email) => {
    await fSendPasswordResetEmail(auth, email)
  }

  const confirmPasswordReset = async (password, oobCode) => {
    await fConfirmPasswordReset(auth, oobCode, password)
  }

  const changePassword = async (currentPassword, newPassword) => {
    const credential = EmailAuthProvider.credential(
      currentUser.email,
      currentPassword
    )

    const user = auth.currentUser

    await reauthenticateWithCredential(user, credential)
      .then(async (data) => {
        await updatePassword(user, newPassword)
          .then((data) => {
            toast.success('Password updated successfully')
          })
          .catch((error) => {
            let { title } = firebaseErrors(error)
            toast.error(title)

            console.log(error)
          })
      })
      .catch((error) => {
        let { title } = firebaseErrors(error)
        toast.error(title)
        console.log(error)
      })
  }

  const updateProfile = async (values) => {
    cleanObject(values)
    await setDoc(
      doc(db, 'users', currentUser.uid),
      {
        ...values,
      },
      { merge: true }
    )
    setcurrentUser({ ...currentUser, ...values })
  }

  const updateProfileImage = async (file) => {
    const storage = getStorage()
    const storageRef = ref(
      storage,
      `users/profile/${currentUser.uid}/${file.name}`
    )
    await uploadBytes(storageRef, file.originFileObj)
    let url = await getDownloadURL(storageRef)
    console.log(url)
    await updateProfile({ profileImage: url })
  }

  const handleResetPassword = async (actionCode, newPassword) => {
    // Localize the UI to the selected language as determined by the lang
    // parameter.

    // Verify the password reset code is valid.
    verifyPasswordResetCode(auth, actionCode)
      .then((email) => {
        const accountEmail = email
        // Save the new password.
        confirmPasswordReset(newPassword, actionCode)
          .then((resp) => {
            router.replace('/')
          })
          .catch((error) => {
            console.log(error)
          })
      })
      .catch((error) => {
        let { title } = firebaseErrors(error)
        toast.error(title)
        console.log(error)
      })
  }

  const updateEmailAddress = async (newEmail, currentPassword) => {
    const credential = EmailAuthProvider.credential(
      currentUser.email,
      currentPassword
    )
    const user = auth.currentUser

    await reauthenticateWithCredential(user, credential)
      .then(async (data) => {
        await updateEmail(user, newEmail)
          .then(async (data) => {
            await updateProfile({ email: newEmail })
            toast.success('Email updated successfully')
            setcurrentUser({ ...currentUser, email: newEmail })
            router.replace('/profile')
          })
          .catch((error) => {
            let { title } = firebaseErrors(error)
            toast.error(title)

            console.log(error)
          })
      })
      .catch((error) => {
        let { title } = firebaseErrors(error)
        toast.error(title)
        console.log(error)
      })
  }

  return {
    firebaseApp,
    db,
    currentUser,
    loading,
    analytics,
    signIn,
    signUp,
    signInWithToken,
    signInWithGoogle,
    signOut,
    sendPasswordResetEmail,
    confirmPasswordReset,
    updateProfile,
    changePassword,
    updateProfileImage,
    handleResetPassword,
    updateEmailAddress,
  }
}
